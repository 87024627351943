import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { About } from "./components/about";
import { Contact } from "./components/contact";
import { Features } from "./components/features";
import { Header } from "./components/header";
import { Mvv } from "./components/mvv";
import { Navigation } from "./components/navigation";
import { Services } from "./components/services";
import JsonData from "./data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <Navigation />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header data={landingPageData.Header} />
              <Features data={landingPageData.Features} />
              <About data={landingPageData.About} />
              <Mvv data={landingPageData.Mvv} />
              <Services data={landingPageData.Servicos} />
              <Contact data={landingPageData.Contact} />
            </>
          }
        />
        <Route
          path="/#servicos"
          element={<Services data={landingPageData.Servicos} />}
        />
        <Route
          path="/#features/2"
          element={<Features data={landingPageData.Features} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
