import React from "react";

export const Mvv = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-12 section-title">
          <h2>Missão, Visão e Valor</h2>
        </div>
        <div className="row p-12">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-4">
                  <h3>{d.title}</h3>
                  {d.title === "Valor" ? (
                    <div dangerouslySetInnerHTML={{ __html: d.text }} />
                  ) : (
                    <p>{d.text}</p>
                  )}
                </div>
              ))
            : "Carregando..."}
        </div>
      </div>
    </div>
  );
};
