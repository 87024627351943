import React from "react";

export const About = (props) => {
  return (
    <div id="sobre" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Sobre</h2>
          <p>{props.data ? props.data.paragraph : "Carregando..."}</p>
        </div>
        <div
          className="row"
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Por que nos escolher?</h2>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "Carregando"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "Carregando"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
