import React from "react";

export const Services = (props) => {
  return (
    <div id="servicos" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Serviços</h2>
          <p>Confira nossa lista de serviços.</p>
        </div>
        <div
          className="row"
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon} />
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "Carregando"}
        </div>
      </div>
    </div>
  );
};
